import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import { PortalProps } from './Portal.props'

const modalRoot = document.querySelector('body') as HTMLElement

export function Portal({ children }: PortalProps) {
  const el = useRef(document.createElement('div'))

  useEffect(() => {
    const current = el.current

    modalRoot!.appendChild(current)
    return () => void modalRoot!.removeChild(current)
  }, [])

  return createPortal(children, el.current)
}
