import React from 'react'
import { Outlet } from 'react-router-dom'

import logo from '@assets/images/logo.png'
import bg from '@assets/images/sign-in-bg.jpg'

import s from './AuthLayout.module.scss'
import type { AuthLayoutProps } from './AuthLayout.props'

export function AuthLayout({ ...props }: AuthLayoutProps) {
  return (
    <div
      className={s.wrapper}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={s.formWrapper}>
        <div className={s.logoWrapper}>
          <img
            src={logo}
            alt="Elite Aviation Training"
          />
        </div>

        <Outlet />
      </div>
    </div>
  )
}
