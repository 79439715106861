import classNames from 'classnames'
import React from 'react'

import s from './Button.module.scss'
import type { ButtonProps } from './Button.props'

export function Button({ children, type = 'button', variant = 'primary', className, ...props }: ButtonProps) {
  return (
    <button
      {...props}
      type={type}
      className={classNames(s.button, className, { [s.outlined]: variant === 'outlined' })}
    >
      {children}
    </button>
  )
}
