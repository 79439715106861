import { PropsWithChildren, createContext, useCallback, useContext, useRef, useState } from 'react'

import { ConfirmPopup } from './ConfirmPopup'
import { ConfirmPopupData, ConfirmPopupProps } from './ConfirmPopup.props'

const ConfirmPopupContext = createContext<(data: ConfirmPopupData) => Promise<boolean>>(async () => true)

export function ConfirmPopupProvider({ children }: PropsWithChildren) {
  const fn = useRef<Function>(() => {})

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [popupState, setPopupState] = useState<ConfirmPopupProps>({
    description: '',
    onClose: () => {},
    onConfirm: () => {},
  })

  const confirm = useCallback(
    (data: ConfirmPopupData): Promise<boolean> =>
      new Promise((resolve) => {
        setPopupState({ ...popupState, ...data })
        setIsOpen(true)
        document.body.style.overflowY = 'hidden'

        fn.current = (choice: boolean) => {
          resolve(choice)
          setIsOpen(false)
          document.body.style.overflowY = 'auto'
        }
      }),
    [setPopupState],
  )

  return (
    <ConfirmPopupContext.Provider value={confirm}>
      {children}
      {isOpen && (
        <ConfirmPopup
          {...popupState}
          onClose={() => fn.current(false)}
          onConfirm={() => fn.current(true)}
        />
      )}
    </ConfirmPopupContext.Provider>
  )
}

export default function useConfirm() {
  return useContext(ConfirmPopupContext)
}
