import { lazy } from 'react'

import type { Route } from '@types'

// auth routes
const SignIn = lazy(() => import('@pages/Auth/SignIn').then((module) => ({ default: module.SignIn })))

// admins routes
const Users = lazy(() => import('@pages/Admins/Users').then((module) => ({ default: module.Users })))
const AdminsCourses = lazy(() => import('@pages/Admins/AdminsCourses').then((module) => ({ default: module.AdminsCourses })))

// clients routes
const ClientsCourses = lazy(() => import('@pages/Clients/ClientsCourses').then((module) => ({ default: module.ClientsCourses })))
const CourseDetail = lazy(() => import('@pages/Clients/CourseDetail').then((module) => ({ default: module.CourseDetail })))
const CourseAttendance = lazy(() => import('@pages/Clients/CourseAttendance').then((module) => ({ default: module.CourseAttendance })))
const AllCourseAttendance = lazy(() => import('@pages/Clients/AllCourseAttendance').then((module) => ({ default: module.AllCourseAttendance })))

export const authRoutes: Route[] = [
  {
    path: '/sign-in',
    title: 'Sign In',
    element: <SignIn />,
  },
]

export const adminsRoutes: Route[] = [
  {
    path: '/dashboard/users',
    title: 'Users',
    element: <Users />,
  },
  {
    path: '/dashboard/courses',
    title: 'Courses',
    element: <AdminsCourses />,
  },
]

export const clientsRoutes: Route[] = [
  {
    path: '/',
    title: 'Courses',
    element: <ClientsCourses />,
  },
  {
    path: '/course/:id',
    title: 'Course Detail',
    element: <CourseDetail />,
  },
  {
    path: '/course/:courseId/attendance/:timetableId',
    title: 'Course Attendance',
    element: <CourseAttendance />,
  },
  {
    path: '/course/:id/attendance',
    title: 'All Course Attendance',
    element: <AllCourseAttendance />,
  },
]
