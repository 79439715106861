import { CreateUser, ServerResponse, User, WithPagination } from '@types'

import { api } from '../api'

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<WithPagination<User>, any>({
      query: (params) => ({
        url: '/users',
        method: 'GET',
        params: {
          page: 1,
          per_page: 50,
          ...params,
        },
      }),
      providesTags: ['users'],
    }),
    getUser: builder.query<User, any>({
      query: () => ({
        url: '/user',
        method: 'GET',
      }),
    }),
    getUserById: builder.query<User, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ServerResponse<User>) => response.data,
      providesTags: ['users'],
    }),
    createUser: builder.mutation<any, CreateUser>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['users'],
    }),
    editUser: builder.mutation<any, { id: number; data: Pick<CreateUser, 'name' | 'email'> }>({
      query: ({ id, data }) => ({
        url: `/users/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['users'],
    }),
    deleteUser: builder.mutation<any, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['users'],
    }),
  }),
})

export const { useGetAllUsersQuery, useLazyGetAllUsersQuery, useGetUserQuery, useGetUserByIdQuery, useCreateUserMutation, useEditUserMutation, useDeleteUserMutation } =
  usersApi
