import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAppSelector } from '@/hooks/useAppSelector'

import { RequireAuthProps } from './RequireAuth.props'

export function RequireAuth({ children }: RequireAuthProps) {
  const { isAuth } = useAppSelector((state) => state.auth)

  if (!isAuth) {
    return <Navigate to="/sign-in" />
  }

  return <>{children}</>
}
