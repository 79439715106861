import classNames from 'classnames'
import React from 'react'

import { Portal } from '@components/Portal'

import { Button } from '@UI/Button'

import { useAnimate } from '@hooks/useAnimate'

import s from './ConfirmPopup.module.scss'
import { ConfirmPopupProps } from './ConfirmPopup.props'

export function ConfirmPopup(props: ConfirmPopupProps) {
  const { title = 'Confirm action', description, confirmBtnLabel = 'Confirm', onClose, onConfirm } = props

  const [closeAnimate, closeHandler] = useAnimate(onClose)
  const [confirmAnimate, confirmHandler] = useAnimate(onConfirm)

  return (
    <Portal>
      <div
        className={classNames(s.confirmPopupWrapper, { [s.animate]: closeAnimate || confirmAnimate })}
        onClick={closeHandler}
      >
        <div
          className={classNames(s.confirmPopupBlock, { [s.animate]: closeAnimate || confirmAnimate })}
          onClick={(ev) => ev.stopPropagation()}
        >
          <h3 className={s.confirmPopupTitle}>{title}</h3>
          <p className={s.confirmPopupText}>{description}</p>
          <div className={s.confirmPopupBtns}>
            <Button
              variant="outlined"
              onClick={closeHandler}
              className={s.confirmPopupBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={confirmHandler}
              className={s.confirmPopupBtn}
            >
              {confirmBtnLabel}
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  )
}
