import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AdminsOnly } from '@components/AdminsOnly'
import { RequireAuth } from '@components/RequireAuth'
import { AuthLayout } from '@components/layouts/AuthLayout'
import { MainLayout } from '@components/layouts/MainLayout'

import { Loader } from '@UI/Loader'

import { adminsRoutes, authRoutes, clientsRoutes } from './constants/routes'

const renderRoutes = (routesArr: any[], requireAuth: boolean, adminsOnly: boolean) =>
  routesArr.map((i) => (
    <Route
      key={i.path}
      path={i.path}
      element={
        <Suspense fallback={<Loader />}>{requireAuth ? <RequireAuth>{adminsOnly ? <AdminsOnly>{i.element}</AdminsOnly> : i.element}</RequireAuth> : i.element}</Suspense>
      }
    />
  ))

export function App() {
  return (
    <Routes>
      <Route
        path={'/'}
        element={<MainLayout />}
      >
        {renderRoutes(adminsRoutes, true, true)}
        {renderRoutes(clientsRoutes, true, false)}
      </Route>

      <Route
        path={'/'}
        element={<AuthLayout />}
      >
        {renderRoutes(authRoutes, false, false)}
      </Route>

      <Route
        path="*"
        element={
          <Navigate
            to={'/'}
            replace
          />
        }
      />
    </Routes>
  )
}
