import React from 'react'
import { Outlet } from 'react-router-dom'

import { Header } from '@UI/Header'

import s from './MainLayout.module.scss'
import type { MainLayoutProps } from './MainLayout.props'

export function MainLayout({ ...props }: MainLayoutProps) {
  return (
    <div className={s.container}>
      <Header />

      <div className={s.wrapper}>
        <Outlet />
      </div>
    </div>
  )
}
