import { AuthResponse } from '@types'

import { api } from '../api'

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<AuthResponse, { email: string; password: string }>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    signOut: builder.mutation<any, any>({
      query: () => ({
        url: 'logout',
        method: 'POST',
      }),
    }),
  }),
})

export const { useSignInMutation, useSignOutMutation } = authApi
