import classNames from 'classnames'
import React from 'react'

import s from './Loader.module.scss'

export function Loader({ short = false }: { short?: boolean }) {
  return (
    <div className={classNames(s.wrapper, { [s.short]: short })}>
      <div className={s.loader}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
