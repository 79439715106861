import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

import { Button } from '@UI/Button'

import { useActions } from '@hooks/useActions'

import { adminsRoutes } from '@/constants/routes'
import { api } from '@/store/api'
import { useSignOutMutation } from '@/store/auth/auth.api'
import { useGetUserQuery } from '@/store/users/users.api'
import logo from '@assets/images/logo.png'

import s from './Header.module.scss'
import type { HeaderProps } from './Header.props'

export function Header({ ...props }: HeaderProps) {
  const dispatch = useDispatch()
  const { setAuth } = useActions()

  const { data: user } = useGetUserQuery({})
  const [signOut, { isLoading }] = useSignOutMutation()

  const handleClick = () => {
    signOut({})
      .unwrap()
      .then(() => {
        localStorage.removeItem('accessToken')
        setAuth(false)
        dispatch(api.util.resetApiState())
      })
      .catch(() => {})
  }

  return (
    <header className={s.header}>
      <Link
        to="/"
        className={s.logo}
      >
        <img
          src={logo}
          alt="Elite Aviation Training"
        />
        <span>Elite Aviation Training</span>
      </Link>

      {user?.roles?.[0]?.name === 'superadmin' && (
        <nav className={s.nav}>
          {adminsRoutes.map((i) => (
            <NavLink
              key={i.path}
              to={i.path}
              end
              className={s.navLink}
            >
              {i.title}
            </NavLink>
          ))}
        </nav>
      )}

      <Button
        className={s.btn}
        onClick={handleClick}
        disabled={isLoading}
      >
        Log Out
      </Button>
    </header>
  )
}
