import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface InitialSate {
  isAuth: boolean
}

const initialState: InitialSate = {
  isAuth: !!localStorage.getItem('accessToken')?.length,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload
    },
  },
})

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer
