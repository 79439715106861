import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ErrorResponse, ServerResponse } from '@types'

import { authActions } from './auth/auth.slice'

const API_URL = process.env.REACT_APP_API_URL

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    headers.set('Accept', 'application/json')
    const token = localStorage.getItem('accessToken')
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError & ServerResponse<ErrorResponse>> = async (args, api, extraOptions): Promise<any> => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    localStorage.removeItem('accessToken')
    api.dispatch(authActions.setAuth(false))

    // try to get a new token
    // const refreshResult = await baseQuery('/tokens/refresh', api, extraOptions)
    // if (refreshResult.data) {
    // store the new token
    // localStorage.setItem('token', token)
    // api.dispatch(tokenReceived(refreshResult.data))
    // retry the initial query
    // result = await baseQuery(args, api, extraOptions)
    // } else {
    // api.dispatch(loggedOut())
    // }
  }
  return result
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['users', 'courses', 'courseFiles', 'timetables', 'attendance'],
  endpoints: (builder) => ({}),
})

export const {} = api
