import React from 'react'
import { Navigate } from 'react-router-dom'

import { Loader } from '@UI/Loader'

import { useGetUserQuery } from '@/store/users/users.api'

import type { AdminsOnlyProps } from './AdminsOnly.props'

export function AdminsOnly({ children }: AdminsOnlyProps) {
  const { data: user, isLoading } = useGetUserQuery({})

  if (isLoading) {
    return <Loader />
  }

  if (user?.roles?.[0]?.name !== 'superadmin') {
    return <Navigate to="/" />
  }

  return <>{children}</>
}
